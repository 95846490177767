import { Button } from '@hermes/web-components'
import Image from 'next/image'
import DownloadOnAppStoreNO from '@lib/assets/svg/app-store-no.svg'
import DownloadOnAppStoreEN from '@lib/assets/svg/app-store-en.svg'
import DownloadOnAppStoreSV from '@lib/assets/svg/app-store-sv.svg'
import DownloadOnGooglePlayNO from '@lib/assets/png/google-play-no.png'
import DownloadOnGooglePlayEN from '@lib/assets/png/google-play-en.png'
import DownloadOnGooglePlaySV from '@lib/assets/png/google-play-sv.png'

export const AppStoreButton = ({
  text,
  url,
  store,
  locale
}: {
  text: string
  url: string
  store: 'app-store' | 'play-store'
  locale: 'no' | 'en' | 'sv' | string
}) => {
  let appStoreImage
  if (locale === 'no') {
    appStoreImage = DownloadOnAppStoreNO
  } else if (locale === 'en') {
    appStoreImage = DownloadOnAppStoreEN
  } else if (locale === 'sv') {
    appStoreImage = DownloadOnAppStoreSV
  } else {
    appStoreImage = DownloadOnAppStoreNO
  }

  let playStoreImage
  if (locale === 'no') {
    playStoreImage = DownloadOnGooglePlayNO
  } else if (locale === 'en') {
    playStoreImage = DownloadOnGooglePlayEN
  } else if (locale === 'sv') {
    playStoreImage = DownloadOnGooglePlaySV
  } else {
    playStoreImage = DownloadOnGooglePlayNO
  }

  switch (store) {
    case 'app-store':
      return (
        <a
          href="https://apps.apple.com/no/app/bookis/id1299680557?itsct=apps_box_badge&amp;itscg=30200"
          target="_blank"
          // style={{ margin: '10px' }}
        >
          <Image src={appStoreImage} alt="Download on the App Store" width={180} height={55} />
        </a>
      )
    case 'play-store':
      return (
        <a
          href="https://play.google.com/store/apps/details?id=com.bookis&pli=1"
          target="_blank"
          // style={{ margin: '10px' }}
        >
          <Image
            src={playStoreImage}
            alt="Download on Google Play"
            width={180}
            height={55}
            style={{ objectFit: 'contain' }}
          />
        </a>
      )
    default:
      return (
        <Button component="a" href={url} variant="contained" sx={{ margin: '10px', whiteSpace: 'nowrap' }}>
          {text}
        </Button>
      )
  }
}
