'use client'

import { Stack, Typography } from '@hermes/web-components'
import Image from 'next/image'
import React from 'react'

export const CmsHeader = ({ title, subtitle, imageUrl }: { title: string; subtitle: string; imageUrl: string }) => (
  <Stack
    justifyContent={{ xs: 'center', md: 'space-evenly' }}
    alignItems={'center'}
    direction={{ xs: 'column', md: 'row' }}
    sx={{ width: '100%', margin: '32px 0' }}
  >
    <Stack justifyContent="center">
      <Typography variant="h2" fontSize={{ md: '36px' }}>
        {title}
      </Typography>
      <Typography
        variant={'body2'}
        component={'span'}
        sx={{ width: '80%', strong: { color: 'primary.main', textDecoration: 'underline' } }}
        suppressHydrationWarning
        dangerouslySetInnerHTML={{ __html: subtitle }}
      />
    </Stack>
    <Stack>
      <Image src={imageUrl} alt="Gift Card Image" width={400} height={300} style={{ objectFit: 'contain' }} />
    </Stack>
  </Stack>
)
