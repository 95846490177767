'use client'

import { Button, Stack, Typography, useTheme } from '@hermes/web-components'
import Image from 'next/image'
import CtaBannerImage from '@lib/assets/jpg/section-5-bg.jpg'
import { AppStoreButton } from './AppStoreButton'

export const CmsCallToActionBanner = ({
  title,
  subtitle,
  primaryButtonText,
  primaryButtonUrl,
  secondaryButtonText,
  secondaryButtonUrl,
  locale
}: {
  title?: string
  subtitle?: string
  primaryButtonText?: string
  primaryButtonUrl?: string
  secondaryButtonText?: string
  secondaryButtonUrl?: string
  locale: string
}) => {
  const theme = useTheme()
  const { up } = theme.breakpoints
  const BREAKPOINT = 'sm'

  return (
    <Stack
      sx={{
        position: 'relative',
        height: '100%',
        width: '100%',
        minHeight: '360px',
        padding: '20px 20px',
        [up(BREAKPOINT)]: {
          padding: '20px 115px'
        },
        borderRadius: '16px',
        margin: '70px 0',
        overflow: 'hidden'
      }}
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={4}
    >
      <Image
        src={CtaBannerImage}
        alt="CTA Banner Background"
        fill
        quality={80}
        style={{ zIndex: -1, objectFit: 'fill' }}
        priority={true}
      />
      <Stack
        direction="column"
        spacing={2}
        sx={{
          textAlign: 'center',
          maxWidth: '80%',
          [up(BREAKPOINT)]: {
            maxWidth: '60%'
          }
        }}
      >
        <Typography
          variant="h1"
          sx={{
            lineHeight: '36px',
            fontSize: '28px',
            [up(BREAKPOINT)]: {
              lineHeight: '56px',
              fontSize: '42px'
            }
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="h3"
          sx={{
            lineHeight: '24px',
            fontSize: '16px',
            [up(BREAKPOINT)]: {
              fontSize: '20px'
            }
          }}
        >
          {subtitle}
        </Typography>
      </Stack>
      <Stack
        sx={{
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          [up(BREAKPOINT)]: {
            flexDirection: 'row'
          }
        }}
      >
        {primaryButtonUrl &&
        (primaryButtonUrl.includes('apps.apple.com') || primaryButtonUrl.includes('itunes.apple.com')) ? (
          <AppStoreButton text={primaryButtonText || ''} url={primaryButtonUrl} store={'app-store'} locale={locale} />
        ) : (
          <Button
            component="a"
            href={primaryButtonUrl}
            variant="contained"
            sx={{ margin: '10px', whiteSpace: 'nowrap', width: 'auto', [up(BREAKPOINT)]: { width: '100%' } }}
          >
            {primaryButtonText}
          </Button>
        )}

        {secondaryButtonUrl && secondaryButtonUrl.includes('play.google.com') ? (
          <AppStoreButton
            text={secondaryButtonText || ''}
            url={secondaryButtonUrl}
            store="play-store"
            locale={locale}
          />
        ) : (
          <Button
            component="a"
            href={secondaryButtonUrl}
            variant="outlined"
            sx={{ margin: '10px', whiteSpace: 'nowrap', width: 'auto', [up(BREAKPOINT)]: { width: '100%' } }}
          >
            {secondaryButtonText}
          </Button>
        )}
      </Stack>
    </Stack>
  )
}
