'use client'

import { Stack, Typography } from '@hermes/web-components'
import React from 'react'

export const CmsText = ({ text }: { text: string }) => {
  const replacedText = text.replace(/<h1>/g, '<Typography variant="h1">').replace(/<\/h1>/g, '</Typography>')

  return (
    <Stack justifyContent={'center'} alignItems={'center'} sx={{ width: '100%', margin: '32px 0' }}>
      <Typography
        variant="h2"
        fontSize="36px"
        suppressHydrationWarning
        dangerouslySetInnerHTML={{ __html: replacedText }}
      />
    </Stack>
  )
}
