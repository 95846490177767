import { Box, Typography, useMediaQuery, useTheme } from '@hermes/web-components'
import { useLocale } from '@hooks/useLocale' // Import next/image
import Image from 'next/image'
import { useRouter } from 'next/navigation'
import { useState } from 'react'

interface ImageUrls {
  imageLargeUrl?: string
  imageMediumUrl?: string
  imageSmallUrl: string
  standardUrl: string
}

interface BlogArticleCardProps {
  slug: string
  imageUrls: ImageUrls
  title: string
  subtitle?: string
}

export const BlogArticleCard = ({ slug, imageUrls, title, subtitle }: BlogArticleCardProps) => {
  const [hovered, setHovered] = useState(false)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const imageUrl = isMobile ? imageUrls.imageSmallUrl : imageUrls.imageLargeUrl || imageUrls.standardUrl
  const router = useRouter()
  const locale = useLocale()
  const targetPath = `/${locale}/b/${slug}`

  return (
    <Box
      maxWidth={650}
      maxHeight={400}
      component="div"
      onMouseEnter={() => {
        setHovered(true)
      }}
      onMouseLeave={() => {
        setHovered(false)
      }}
      onClick={() => router.push(targetPath)}
      justifyContent="center"
      sx={{
        '&:hover': {
          cursor: 'pointer'
        }
      }}
    >
      <Box sx={{ position: 'relative', width: '100%', paddingBottom: '60%' }}>
        <Image src={imageUrl} alt={title} fill style={{ borderRadius: '8px', objectFit: 'cover' }} />
      </Box>
      <Box sx={{ paddingTop: '4px' }}>
        <Typography variant="button" sx={!hovered ? { color: 'black' } : {}}>
          {title}
        </Typography>
        {subtitle && (
          <Typography variant="caption" sx={{ color: 'black' }}>
            {subtitle}
          </Typography>
        )}
      </Box>
    </Box>
  )
}
