'use client'

import { Button, Stack, Typography, useTheme } from '@hermes/web-components'
import React from 'react'
import Image from 'next/image'
import { AppStoreButton } from './AppStoreButton'

export const CmsHeaderBanner = ({
  title,
  subtitle,
  imageUrl,
  primaryButton,
  primaryLink,
  secondaryButton,
  secondaryButtonEn,
  secondaryLink,
  subtitleEn,
  titleEn,
  primaryButtonEn,
  locale
}: {
  title: string
  subtitle: string
  imageUrl: string
  primaryButton: string
  primaryButtonEn: string | null
  primaryLink: string
  secondaryButton: string
  secondaryButtonEn: string | null
  secondaryLink: string
  subtitleEn: string | null
  titleEn: string | null
  locale: string
}) => {
  const theme = useTheme()
  const { down } = theme.breakpoints

  return (
    <Stack>
      <Stack
        sx={{
          position: 'relative',
          height: '100%',
          width: '100%',
          padding: '103px 115px',
          borderRadius: '16px',
          margin: '70px 0',
          overflow: 'hidden',
          [down('sm')]: {
            padding: '44px 24px',
            margin: '20px 0'
          }
        }}
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        spacing={4}
      >
        <Image src={imageUrl} alt={title} fill style={{ zIndex: -1, objectFit: 'cover' }} />
        <Stack
          direction="column"
          spacing={2}
          sx={{
            textAlign: 'left',
            maxWidth: '40%',
            [down('sm')]: {
              maxWidth: '60%'
            }
          }}
        >
          <Typography variant="h2" fontSize={{ xs: '28px', lg: '36px' }}>
            {titleEn || title}
          </Typography>
          <Typography
            variant="h4"
            fontSize={{ xs: '16px', lg: '20px' }}
            sx={{
              display: 'flex', // Default for desktop
              [down('sm')]: {
                display: 'none'
              }
            }}
          >
            {subtitleEn || subtitle}
          </Typography>
        </Stack>
        <Stack
          direction={'row'}
          spacing={2}
          justifyContent="center"
          alignItems="center"
          sx={{
            maxWidth: '70%',
            display: 'flex', // Default for desktop
            [down('sm')]: {
              display: 'none'
            }
          }}
        >
          {primaryLink && primaryLink.includes('apps.apple.com') ? (
            <AppStoreButton
              text={primaryButtonEn || primaryButton}
              url={primaryLink}
              store="app-store"
              locale={locale}
            />
          ) : (
            <Button
              component="a"
              href={primaryLink}
              variant="contained"
              sx={{
                margin: '10px',
                whiteSpace: 'nowrap',
                width: '100%', // Default for desktop
                [down('sm')]: {
                  width: 'auto'
                }
              }}
            >
              {primaryButtonEn || primaryButton}
            </Button>
          )}

          {secondaryLink && secondaryLink.includes('play.google.com') ? (
            <AppStoreButton
              text={secondaryButtonEn || secondaryButton}
              url={secondaryLink}
              store="play-store"
              locale={locale}
            />
          ) : (
            <>
              {secondaryLink && (
                <Button
                  component="a"
                  href={secondaryLink}
                  variant="outlined"
                  sx={{
                    margin: '10px',
                    whiteSpace: 'nowrap',
                    width: '100%', // Default for desktop
                    [down('sm')]: {
                      width: 'auto'
                    }
                  }}
                >
                  {secondaryButtonEn || secondaryButton}
                </Button>
              )}
            </>
          )}
        </Stack>
      </Stack>
      <Stack
        direction={'column'}
        justifyContent="center"
        alignItems="center"
        sx={{
          display: 'none', // Default for desktop
          [down('sm')]: {
            display: 'flex'
          }
        }}
      >
        {primaryLink && primaryLink.includes('apps.apple.com') ? (
          <AppStoreButton text={primaryButtonEn || primaryButton} url={primaryLink} store="app-store" locale={locale} />
        ) : (
          <Button
            component="a"
            href={primaryLink}
            variant="contained"
            sx={{
              margin: '10px',
              whiteSpace: 'nowrap',
              width: '100%', // Default for desktop
              [down('sm')]: {
                width: 'auto'
              }
            }}
          >
            {primaryButtonEn || primaryButton}
          </Button>
        )}

        {secondaryLink && secondaryLink.includes('play.google.com') ? (
          <AppStoreButton
            text={secondaryButtonEn || secondaryButton}
            url={secondaryLink}
            store="play-store"
            locale={locale}
          />
        ) : (
          <>
            {secondaryLink && (
              <Button
                component="a"
                href={secondaryLink}
                variant="outlined"
                sx={{
                  margin: '10px',
                  whiteSpace: 'nowrap',
                  width: '100%', // Default for desktop
                  [down('sm')]: {
                    width: 'auto'
                  }
                }}
              >
                {secondaryButtonEn || secondaryButton}
              </Button>
            )}
          </>
        )}
      </Stack>
    </Stack>
  )
}
