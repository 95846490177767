'use client'

import { BlogArticleCard } from '@components/BlogArticleCard'
import { Container, Grid, Stack } from '@hermes/web-components'
import useTranslateMessage from '@hooks/useTranslateMessage'

export const BlogArticleCardGrid = () => {
  const { t } = useTranslateMessage()

  const articles = [
    {
      slug: 'bestselgere-1',
      title: t({ defaultMessage: 'Bestsellers', id: 'explore.blogCard.bestselgere.title' }),
      imageUrl: {
        imageLargeUrl:
          'https://strpi-content-prod.s3.eu-central-1.amazonaws.com/large_Category_bestselgere_c2d4d9b580.png',
        imageMediumUrl:
          'https://strpi-content-prod.s3.eu-central-1.amazonaws.com/medium_Category_bestselgere_c2d4d9b580.png',
        imageSmallUrl:
          'https://strpi-content-prod.s3.eu-central-1.amazonaws.com/small_Category_bestselgere_c2d4d9b580.png',
        standardUrl: 'https://strpi-content-prod.s3.eu-central-1.amazonaws.com/Category_bestselgere_c2d4d9b580.png'
      }
    },
    {
      slug: 'book-tok',
      title: t({ defaultMessage: '#BookTok', id: 'explore.blogCard.book-tok.title' }),
      subtitle: t({ defaultMessage: 'Popular books from TikTok', id: 'explore.blogCard.book-tok.subtitle' }),
      imageUrl: {
        imageSmallUrl: 'https://strpi-content-prod.s3.eu-central-1.amazonaws.com/small_book_Tok_0e1826bc6a.png',
        standardUrl: 'https://strpi-content-prod.s3.eu-central-1.amazonaws.com/book_Tok_0e1826bc6a.png'
      }
    },
    {
      slug: 'skjonnlitteratur',
      title: t({ defaultMessage: 'Novels and Fiction', id: 'explore.blogCard.skjonnlitteratur.title' }),
      imageUrl: {
        imageLargeUrl:
          'https://strpi-content-prod.s3.eu-central-1.amazonaws.com/large_preview_romaner_og_skjonnlitteratur_b6afb90e63.png',
        imageMediumUrl:
          'https://strpi-content-prod.s3.eu-central-1.amazonaws.com/medium_preview_romaner_og_skjonnlitteratur_b6afb90e63.png',
        imageSmallUrl:
          'https://strpi-content-prod.s3.eu-central-1.amazonaws.com/small_preview_romaner_og_skjonnlitteratur_b6afb90e63.png',
        standardUrl:
          'https://strpi-content-prod.s3.eu-central-1.amazonaws.com/preview_romaner_og_skjonnlitteratur_b6afb90e63.png'
      }
    },
    {
      slug: 'krim-og-mysterier',
      title: t({ defaultMessage: 'Crime and Mysteries', id: 'explore.blogCard.krim-og-mysterier.title' }),
      imageUrl: {
        imageLargeUrl:
          'https://strpi-content-prod.s3.eu-central-1.amazonaws.com/large_preview_krim_og_mysterier_a9419aea76.png',
        imageMediumUrl:
          'https://strpi-content-prod.s3.eu-central-1.amazonaws.com/medium_preview_krim_og_mysterier_a9419aea76.png',
        imageSmallUrl:
          'https://strpi-content-prod.s3.eu-central-1.amazonaws.com/small_preview_krim_og_mysterier_a9419aea76.png',
        standardUrl: 'https://strpi-content-prod.s3.eu-central-1.amazonaws.com/preview_krim_og_mysterier_a9419aea76.png'
      }
    },
    {
      slug: 'personlig-utvikling',
      title: t({ defaultMessage: 'Personal Development', id: 'explore.blogCard.personlig-utvikling.title' }),
      imageUrl: {
        imageLargeUrl:
          'https://strpi-content-prod.s3.eu-central-1.amazonaws.com/large_preview_personlig_utvikling_15067ed726.png',
        imageMediumUrl:
          'https://strpi-content-prod.s3.eu-central-1.amazonaws.com/medium_preview_personlig_utvikling_15067ed726.png',
        imageSmallUrl:
          'https://strpi-content-prod.s3.eu-central-1.amazonaws.com/small_preview_personlig_utvikling_15067ed726.png',
        standardUrl:
          'https://strpi-content-prod.s3.eu-central-1.amazonaws.com/preview_personlig_utvikling_15067ed726.png'
      }
    },
    {
      slug: 'biografier',
      title: t({ defaultMessage: 'Biographies', id: 'explore.blogCard.biografier.title' }),
      imageUrl: {
        imageSmallUrl:
          'https://strpi-content-prod.s3.eu-central-1.amazonaws.com/small_preview_biografier_a46b510ffc.png',
        standardUrl: 'https://strpi-content-prod.s3.eu-central-1.amazonaws.com/preview_biografier_a46b510ffc.png'
      }
    },
    {
      slug: 'barn-1',
      // eslint-disable-next-line prettier/prettier
      title: t({ defaultMessage: 'Children\'s Books (0-6 years)', id: 'explore.blogCard.barn-1.title' }),
      imageUrl: {
        imageSmallUrl:
          'https://strpi-content-prod.s3.eu-central-1.amazonaws.com/small_preview_barneboker_0_6_ar_2057381ed1.png',
        standardUrl: 'https://strpi-content-prod.s3.eu-central-1.amazonaws.com/preview_barneboker_0_6_ar_2057381ed1.png'
      }
    },
    {
      slug: 'barn-og-ungdom',
      title: t({ defaultMessage: 'Youth', id: 'explore.blogCard.barn-og-ungdom.title' }),
      imageUrl: {
        imageLargeUrl:
          'https://strpi-content-prod.s3.eu-central-1.amazonaws.com/large_Category_hero_ungdom_be86be51f4.png',
        imageMediumUrl:
          'https://strpi-content-prod.s3.eu-central-1.amazonaws.com/medium_Category_hero_ungdom_be86be51f4.png',
        imageSmallUrl:
          'https://strpi-content-prod.s3.eu-central-1.amazonaws.com/small_Category_hero_ungdom_be86be51f4.png',
        standardUrl: 'https://strpi-content-prod.s3.eu-central-1.amazonaws.com/Category_hero_ungdom_be86be51f4.png'
      }
    },
    {
      slug: 'mat-and-drikke',
      title: t({ defaultMessage: 'Foods and Drinks', id: 'explore.blogCard.mat-and-drikke.title' }),
      imageUrl: {
        imageSmallUrl:
          'https://strpi-content-prod.s3.eu-central-1.amazonaws.com/small_preview_livsstil_mat_og_drikke_9310363526.png',
        standardUrl:
          'https://strpi-content-prod.s3.eu-central-1.amazonaws.com/preview_livsstil_mat_og_drikke_9310363526.png'
      }
    },
    {
      slug: 'livsstil-hobby-og-fritid',
      title: t({
        defaultMessage: 'Lifestyle, Hobby, and Leisure',
        id: 'explore.blogCard.livsstil-hobby-og-fritid.title'
      }),
      imageUrl: {
        imageSmallUrl:
          'https://strpi-content-prod.s3.eu-central-1.amazonaws.com/small_preview_livsstil_hobby_fritid_dc3a053f42.png',
        standardUrl:
          'https://strpi-content-prod.s3.eu-central-1.amazonaws.com/preview_livsstil_hobby_fritid_dc3a053f42.png'
      }
    },
    {
      slug: 'fantasy-science-fiction',
      title: t({ defaultMessage: 'Fantasy and Science Fiction', id: 'explore.blogCard.fantasy-science-fiction.title' }),
      imageUrl: {
        imageLargeUrl:
          'https://strpi-content-prod.s3.eu-central-1.amazonaws.com/large_preview_fantasy_and_science_fiction_2dcc9c0554.png',
        imageMediumUrl:
          'https://strpi-content-prod.s3.eu-central-1.amazonaws.com/medium_preview_fantasy_and_science_fiction_2dcc9c0554.png',
        imageSmallUrl:
          'https://strpi-content-prod.s3.eu-central-1.amazonaws.com/small_preview_fantasy_and_science_fiction_2dcc9c0554.png',
        standardUrl:
          'https://strpi-content-prod.s3.eu-central-1.amazonaws.com/preview_fantasy_and_science_fiction_2dcc9c0554.png'
      }
    },
    {
      slug: 'Historie-filosofi',
      title: t({ defaultMessage: 'History & Philosophy', id: 'explore.blogCard.historie-filosofi.title' }),
      imageUrl: {
        imageLargeUrl: 'https://strpi-content-prod.s3.eu-central-1.amazonaws.com/large_History_27a4c93712.png',
        imageMediumUrl: 'https://strpi-content-prod.s3.eu-central-1.amazonaws.com/medium_History_27a4c93712.png',
        imageSmallUrl: 'https://strpi-content-prod.s3.eu-central-1.amazonaws.com/small_History_27a4c93712.png',
        standardUrl: ''
      }
    }
  ]

  return (
    <Stack direction={'column'} spacing={4} marginTop={4}>
      <Grid container rowSpacing={4} columnSpacing={0} justifyContent="center">
        {articles.map((article) => (
          <Grid
            columns={2}
            container
            item
            key={article.slug}
            xs={1}
            sm={1}
            justifyContent={'center'}
            margin={0}
            padding={0}
          >
            <Container>
              <BlogArticleCard
                slug={article.slug}
                imageUrls={article.imageUrl}
                title={article.title}
                subtitle={article.subtitle}
              />
            </Container>
          </Grid>
        ))}
      </Grid>
    </Stack>
  )
}
